/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Tajawal_200ExtraLight = require('./Tajawal_200ExtraLight.ttf');
export const Tajawal_300Light = require('./Tajawal_300Light.ttf');
export const Tajawal_400Regular = require('./Tajawal_400Regular.ttf');
export const Tajawal_500Medium = require('./Tajawal_500Medium.ttf');
export const Tajawal_700Bold = require('./Tajawal_700Bold.ttf');
export const Tajawal_800ExtraBold = require('./Tajawal_800ExtraBold.ttf');
export const Tajawal_900Black = require('./Tajawal_900Black.ttf');
